// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-button {
  display: flex;
  align-items: center;
  color: #6b7280;
  font-size: 16px;
  text-decoration: none;
}
.link-button svg {
  margin-right: 6px;
}
.link-button svg path {
  transition: 0.3s linear;
  stroke: #6b7280;
}
.link-button:hover {
  color: #3a343a;
}
.link-button:hover svg path {
  stroke: #3a343a;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/link-button/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;AACJ;AACI;EACE,iBAAA;AACN;AACM;EACE,uBAAA;EACA,eAAA;AACR;AAGI;EACE,cAAA;AADN;AAIQ;EACE,eAAA;AAFV","sourcesContent":[".link-button {\n    display: flex;\n    align-items: center;\n    color: #6b7280;\n    font-size: 16px;\n    text-decoration: none;\n\n    svg {\n      margin-right: 6px;\n\n      path {\n        transition: 0.3s linear;\n        stroke: #6b7280;\n      }\n    }\n\n    &:hover {\n      color: #3a343a;\n\n      svg {\n        path {\n          stroke: #3a343a;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
