// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModalPortal .ReactModal__Overlay .ReactModal__Content {
  position: absolute;
  background: rgb(255, 255, 255);
  overflow: auto;
  outline: none;
}
.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .modal-top .title {
  margin: 0;
  color: #3A3A3A;
  font-size: 22px;
  font-weight: 700;
}
.ReactModalPortal .ReactModal__Overlay .ReactModal__Content .close-modal-btn {
  width: 30px;
  height: 30px;
  padding: 5px !important;
  border-radius: 50% !important;
}
.ReactModalPortal .ReactModal__Overlay .certificate {
  width: 80%;
  padding: 20px;
  overflow: unset;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/index.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;EACA,8BAAA;EACA,cAAA;EACA,aAAA;AADN;AAIQ;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AAFV;AAMM;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,6BAAA;AAJR;AAOI;EACE,UAAA;EAEA,aAAA;EACA,eAAA;AANN;;AAaA;EACE,UAAA;EACA,iCAAA;AAVF;;AAaA;EACE,UAAA;AAVF;;AAaA;EACE,UAAA;AAVF","sourcesContent":[".ReactModalPortal {\n  .ReactModal__Overlay {\n    .ReactModal__Content {\n      position: absolute;\n      background: rgb(255, 255, 255);\n      overflow: auto;\n      outline: none;\n\n      .modal-top {\n        .title {\n          margin: 0;\n          color: #3A3A3A;\n          font-size: 22px;\n          font-weight: 700;\n        }\n      }\n\n      .close-modal-btn {\n        width: 30px;\n        height: 30px;\n        padding: 5px !important;\n        border-radius: 50% !important;\n      }\n    }\n    .certificate {\n      width: 80%;\n      // height: 90%;\n      padding: 20px;\n      overflow: unset;\n    }\n  }\n}\n\n\n\n.ReactModal__Overlay {\n  opacity: 0;\n  transition: all 500ms ease-in-out;\n}\n\n.ReactModal__Overlay--after-open {\n  opacity: 1;\n}\n\n.ReactModal__Overlay--before-close {\n  opacity: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
