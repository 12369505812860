// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-modal {
  width: 84%;
  padding: 30px 30px 0;
  overflow: unset;
}
.terms-modal hr {
  margin: 25px auto 20px;
}
.terms-modal .terms-content .content-body {
  height: 500px;
  overflow: auto;
}
.terms-modal .terms-content .content-body::-webkit-scrollbar {
  width: 6px;
}
.terms-modal .terms-content .content-body::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 3px;
  -webkit-transition: 0.3s all;
  transition: 0.3s all;
}
.terms-modal .terms-content .content-body::-webkit-scrollbar-thumb:hover {
  background-color: #7a7a7a;
}
.terms-modal .terms-content .content-body p {
  width: 95%;
  margin-bottom: 30px;
  color: #3A3A3A;
  font-size: 14px;
  line-height: 30px;
}
@media (max-width: 767px) {
  .terms-modal {
    width: 100%;
    padding: 20px 20px 0;
    bottom: 0px !important;
    top: unset !important;
    transform: translate(-50%, 0) !important;
  }
  .terms-modal .modal-top .title {
    font-size: 18px !important;
  }
  .terms-modal .terms-content hr {
    margin: 16px 0 12px;
  }
  .terms-modal .terms-content .content-body {
    height: 80vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/modals/terms.modal.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,oBAAA;EACA,eAAA;AACJ;AACI;EACI,sBAAA;AACR;AAGQ;EACI,aAAA;EACA,cAAA;AADZ;AAGY;EACI,UAAA;AADhB;AAGgB;EACI,yBAAA;EACA,kBAAA;EACA,4BAAA;EAAA,oBAAA;AADpB;AAGoB;EACI,yBAAA;AADxB;AAMY;EACI,UAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAJhB;AASI;EAtCJ;IAuCQ,WAAA;IACA,oBAAA;IAEA,sBAAA;IACA,qBAAA;IACA,wCAAA;EAPN;EAUU;IACI,0BAAA;EARd;EAaU;IACI,mBAAA;EAXd;EAcU;IACI,YAAA;EAZd;AACF","sourcesContent":[".terms-modal {\n    width: 84%;\n    padding: 30px 30px 0;\n    overflow: unset;\n\n    hr {\n        margin: 25px auto 20px;\n    }\n\n    .terms-content {\n        .content-body {\n            height: 500px;\n            overflow: auto;\n\n            &::-webkit-scrollbar {\n                width: 6px;\n\n                &-thumb {\n                    background-color: #D9D9D9;\n                    border-radius: 3px;\n                    transition: 0.3s all;\n\n                    &:hover {\n                        background-color: #7a7a7a;\n                    }\n                }\n            }\n\n            p {\n                width: 95%;\n                margin-bottom: 30px;\n                color: #3A3A3A;\n                font-size: 14px;\n                line-height: 30px;\n            }\n        }\n    }\n\n    @media(max-width: 767px) {\n        width: 100%;\n        padding: 20px 20px 0;\n\n        bottom: 0px !important;\n        top: unset !important;\n        transform: translate(-50%, 0) !important;\n\n        .modal-top {\n            .title {\n                font-size: 18px !important;\n            }\n        }\n\n        .terms-content {\n            hr {\n                margin: 16px 0 12px;\n            }\n\n            .content-body {\n                height: 80vh;\n            }\n        }\n\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
