// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/arrow-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  justify-content: center;
  min-width: 250px;
}
.pagination .page-item {
  margin: 0 4px;
}
.pagination .page-item .page-link {
  border: none;
  font-size: 16px;
  color: #6B7280;
  border-radius: 3px;
  padding: 4px 8px;
  line-height: 1;
  font-weight: 400;
  height: 25px;
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
  background-color: transparent;
}
.pagination .page-item .page-link:hover {
  background-color: #EFEFEF;
}
.pagination .page-item .page-link.prev, .pagination .page-item .page-link.next {
  display: block;
  width: 25px;
  height: 25px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(-90deg);
}
.pagination .page-item .page-link.prev.prev, .pagination .page-item .page-link.next.prev {
  transform: rotate(90deg);
}
.pagination .page-item.active .page-link {
  background-color: #987EE7;
  color: #fff;
}
@media (max-width: 767px) {
  .pagination {
    min-width: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pagination/index.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;AACJ;AACI;EACE,YAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EAEA,YAAA;AAAN;AAGM;EACE,gBAAA;EACA,6BAAA;AADR;AAIM;EACE,yBAAA;AAFR;AAKM;EAEE,cAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,4BAAA;EACA,kCAAA;EACA,yBAAA;AAJR;AAMQ;EACE,wBAAA;AAJV;AAYM;EACE,yBAAA;EACA,WAAA;AAVR;AA6BE;EApEF;IAqEI,gBAAA;EA1BF;AACF","sourcesContent":[".pagination {\n  justify-content: center;\n  min-width: 250px;\n\n  .page-item {\n    margin: 0 4px;\n\n    .page-link {\n      border: none;\n      font-size: 16px;\n      color: #6B7280;\n      border-radius: 3px;\n      padding: 4px 8px;\n      line-height: 1;\n      font-weight: 400;\n      // width: 25px;\n      height: 25px;\n\n\n      &:focus {\n        box-shadow: none;\n        background-color: transparent;\n      }\n\n      &:hover {\n        background-color: #EFEFEF;\n      }\n\n      &.prev,\n      &.next {\n        display: block;\n        width: 25px;\n        height: 25px;\n        background-image: url(\"/assets/icons/arrow-down.svg\");\n        background-repeat: no-repeat;\n        background-position: center center;\n        transform: rotate(-90deg);\n\n        &.prev {\n          transform: rotate(90deg);\n        }\n      }\n\n\n    }\n\n    &.active {\n      .page-link {\n        background-color: #987EE7;\n        color: #fff;\n      }\n    }\n\n\n\n\n\n\n    // a:hover, a:focus,\n    // a:hover:before, a:hover:after,\n    // a:focus:before, a:focus:after {\n    //     color: #000;\n    // }\n\n\n\n  }\n\n  @media(max-width:767px) {\n    min-width: unset;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
