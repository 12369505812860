import React from "react";
import "./terms.modal.scss";

export default function TermsModal() {
  return (
    <div className="terms-content">
      <hr />

      <div className="content-body">
        <p>
          제 1 장 총 칙 <br />
          제 1 조 목적 <br />
          본 약관은 (주)크릭앤리버엔터테인먼트(이하 "회사"라 한다)가 운영하는
          웹사이트(이하 “사이트” 또는 “미디어DB”라 한다)에서 제공하는
          서비스(이하 합하여 "서비스"라 한다)를 이용함에 있어 회사와 이용자의
          권리, 의무 및 책임사항을 규정함을 목적으로 합니다. <br />
          제 2 조 용어의 정의 <br />
          1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br />
          ① '사이트'란 회사가 이용자에게 서비스를 제공하기 위하여 컴퓨터 등
          정보통신설비를 이용하여 구성한 가상의 공간을 의미하며, 서비스를
          제공받을 수 있는 아래의 사이트를 말합니다.가) 미디어DB :
          www.mediadb.co.kr나) 미디어통 : www.mediatong.co.kr <br />
          ② '일반회원(이하 "회원"이라 한다)'이란 회사에 개인정보를 제공하여
          회원등록을 한 자로서, 회사와 서비스 이용 계약을 체결하고 회원 아이디를
          부여받은 자를 의미합니다. <br />
          ③ '회원 아이디(이하 "ID"라 한다)'란 회원의 식별과 회원의 서비스 이용을
          위하여 회원이 선정하고 회사가 승인하는 문자 또는 숫자의 조합을
          의미합니다. <br />
          ④ '비밀번호'란 회원이 부여 받은 ID와 일치된 회원임을 확인하고, 회원의
          개인정보를 보호하기 위하여 회원이 정한 문자와 숫자의 조합을
          의미합니다. <br />
          ⑤'등록해지(이하 "해지"라 한다)'란 회사 또는 회원이 이용계약을 해지하는
          것을 의미합니다. <br />
          2. 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는
          관계법령 및 서비스별 안내에서 정하는 바에 의합니다. <br />
          제 3 조 이용약관의 효력 및 변경 <br />
          1. 회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 각 서비스 사이트의
          초기 서비스화면에 게시합니다. <br />
          2. 회사는 “약관의 규제에 관한 법률”, “전자거래기본법”, “전자 서명법”,
          ”정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법을 위배하지
          않는 범위에서 본 약관을 개정할 수 있습니다. <br />
          3. 회사는 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여
          현행 약관과 함께 회사가 제공하는 서비스 사이트의 초기화면에 그
          적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 이 경우 회사는
          개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록
          표시합니다. <br />
          4. 회원은 변경된 약관이 공지된 지 15일 이내에 거부의사를 표명할 수
          있습니다. 회원이 거부하는 경우 본 서비스 제공자인 회사는 15일의 기간을
          정하여 회원에게 사전 통지 후 당해 회원과의 계약을 해지할 수 있습니다.
          만약, 회원이 거부의사를 표시하지 않고 "서비스"를 계속 이용하는
          경우에는 동의하는 것으로 간주합니다. <br />
          5. 변경된 약관에 대한 정보를 알지 못해 발생하는 회원의 피해는 회사가
          책임지지 않습니다. <br />
          제 4 조 약관 외 준칙 <br />
          1. 본 약관은 회사가 제공하는 서비스에 관하여 별도의 정책 및 운영규칙과
          함께 적용됩니다. <br />
          2. 약관에 명시되지 아니한 사항과 본 약관의 해석에 관하여는 관계법령에
          따릅니다. <br />
        </p>
      </div>
    </div>
  );
}
