// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page .search {
  margin-top: 60px;
}
.home-page .special-recruitment,
.home-page .career-insigts,
.home-page .all-jobs {
  margin-top: 80px;
}
@media (max-width: 767px) {
  .home-page .all-jobs {
    margin-top: 40px;
  }
  .home-page .career-insigts,
  .home-page .special-recruitment {
    margin-top: 60px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/index.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGE;;;EAGE,gBAAA;AADJ;AAIE;EACE;IACE,gBAAA;EAFJ;EAKE;;IAEE,gBAAA;EAHJ;AACF","sourcesContent":[".home-page {\n  .search {\n    margin-top: 60px;\n  }\n\n  .special-recruitment,\n  .career-insigts,\n  .all-jobs {\n    margin-top: 80px;\n  }\n\n  @media(max-width:767px) {\n    .all-jobs {\n      margin-top: 40px;\n    }\n\n    .career-insigts,\n    .special-recruitment {\n      margin-top: 60px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
