// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.special-recruitment .special-top {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.special-recruitment .special-top .title {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.special-recruitment .special-top .swiper-pagination {
  position: relative;
  width: auto;
  margin: 0;
  bottom: unset;
  color: #98A6AD;
  font-size: 12px;
}
.special-recruitment .recruitment-list .swiper .swiper-slide {
  margin-top: 40px !important;
}
@media (max-width: 767px) {
  .special-recruitment .recruitment-list .swiper .swiper-slide {
    margin-top: 12px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/special-recruitment/index.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAJ;AAEI;EACE,eAAA;EACA,gBAAA;EACA,SAAA;AAAN;AAGI;EACE,kBAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;AADN;AAQM;EACE,2BAAA;AANR;AAYE;EAGM;IACE,2BAAA;EAZR;AACF","sourcesContent":[".special-recruitment {\n  .special-top {\n    margin-bottom: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n\n    .title {\n      font-size: 20px;\n      font-weight: 700;\n      margin: 0;\n    }\n\n    .swiper-pagination {\n      position: relative;\n      width: auto;\n      margin: 0;\n      bottom: unset;\n      color: #98A6AD;\n      font-size: 12px;\n    }\n  }\n\n\n  .recruitment-list {\n    .swiper {\n      .swiper-slide {\n        margin-top: 40px !important;\n      }\n    }\n  }\n\n\n  @media(max-width:767px) {\n    .recruitment-list {\n      .swiper {\n        .swiper-slide {\n          margin-top: 12px !important;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
