// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reference-card {
  padding: 20px 14px;
  border-radius: 10px;
  border: 1px solid #E7E7E7;
  background: #FFF;
}
.reference-card span {
  color: #8C8C8C;
  font-size: 10px;
  font-weight: 500;
}
.reference-card hr {
  margin: 12px 0 !important;
}
.reference-card .card-content .type {
  color: #5A5A5A;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
}
.reference-card .card-content a {
  color: #2D9CDB;
  font-size: 12px;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/cards/reference.card.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AACJ;AACI;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AACR;AAEI;EACI,yBAAA;AAAR;AAIQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AAFZ;AAKQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;AAHZ","sourcesContent":[".reference-card {\n    padding: 20px 14px;\n    border-radius: 10px;\n    border: 1px solid #E7E7E7;\n    background: #FFF;\n\n    span {\n        color: #8C8C8C;\n        font-size: 10px;\n        font-weight: 500;\n    }\n\n    hr {\n        margin: 12px 0 !important;\n    }\n\n    .card-content {\n        .type {\n            color: #5A5A5A;\n            font-size: 14px;\n            font-weight: 700;\n            margin-bottom: 10px;\n        }\n\n        a {\n            color: #2D9CDB;\n            font-size: 12px;\n            font-weight: 700;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
