// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bread-crumb {
  margin: 2px 0 16px 25px;
  display: flex;
  align-items: center;
}
.bread-crumb a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #6B7280;
  font-size: 12px;
}
.bread-crumb a img {
  width: 16px;
  margin: 0 5px;
}
.bread-crumb a:hover {
  color: #3a3a3a;
}`, "",{"version":3,"sources":["webpack://./src/components/bread-crumb/index.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,cAAA;EACA,eAAA;AACR;AACQ;EACI,WAAA;EACA,aAAA;AACZ;AAEQ;EACI,cAAA;AAAZ","sourcesContent":[".bread-crumb {\n    margin: 2px 0 16px 25px;\n    display: flex;\n    align-items: center;\n\n    a {\n        display: flex;\n        align-items: center;\n        text-decoration: none;\n        color: #6B7280;\n        font-size: 12px;\n\n        img {\n            width: 16px;\n            margin: 0 5px;\n        }\n\n        &:hover {\n            color: #3a3a3a;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
