// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-checkbox {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 1;
}
.simple-checkbox input {
  width: auto;
  accent-color: #7653DF;
  border-radius: 4px;
  border: 1px solid #7653DF;
  background: #EEEAFB;
  width: 15px;
  height: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/inputs/simple-checkbox.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;AACJ;AAAI;EACI,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AAER","sourcesContent":[".simple-checkbox {\n    display: flex;\n    align-items: center;\n    width: fit-content;\n    line-height: 1;\n    input {\n        width: auto;\n        accent-color: #7653DF;\n        border-radius: 4px;\n        border: 1px solid #7653DF;\n        background: #EEEAFB;\n        width: 15px;\n        height: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
